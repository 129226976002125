
html, body, #root {
    width: 100%;
    height: 100%;
    background-color: #48535a;
}


.container-fluid {
    margin-top: 1vh;
    width: 100%;
    height: 100%;
}
.editor-input{
    width: 100%;
    height: 90vh;
    border: 2px solid white;
    font-size: 12px;
    font-family: 'Source Code Pro', monospace;
    color: white;
    background-color: #292F33;
    padding: 5px;
    box-shadow: 4px 6px 5px 4px #292F33;
}

#preview {
    background-color: #E1E8ED;
    border: 2px solid #292F33;
    font-family: 'Poppins', sans-serif;
    color:  #292F33;
    padding: 20px;
    height: 90vh;
    overflow: auto;
    box-shadow: 4px 6px 5px 4px;
}


#preview-title{
    font-family: 'Source Code Pro', monospace;
    color:  white;
    height: 100%;
    border-top: #292F33 2px solid;
    border-left: #292F33 2px solid;
    margin-bottom: 0px;
    padding-left: 5px;
    color: white;
    background-color: #55ACEE;
    width: 100%;
    }
#preview-window-max, #preview-window-min {
    max-height: 40px;
    background-color: #55ACEE;
    height: 100%;
    border-top: #292F33 2px solid;
    border-right: #292F33 2px solid;
    margin-bottom: 0px;
    width: 100%;
    display: block;
    padding-right: 5px;
    color: #292F33;
    transition: ease 0.4s;
}

#preview-window-max:hover, #preview-window-min:hover {
    cursor: pointer;
    color: rgba(255, 255, 255, 0.71);
}
 #editor-title {
    font-family: 'Source Code Pro', monospace;
    color:  white;
    height: 100%;
    border-top: white 2px solid;
    border-left: white 2px solid;
    margin-bottom: 0px;
    padding-left: 5px;
    color: white;
    background-color: #55ACEE;
}

#editor-window-max, #editor-window-min{
    max-height: 40px;
    background-color: #55ACEE;
    height: 100%;
    border-top: white 2px solid;
    border-right: white 2px solid;
    margin-bottom: 0px;
    width: 100%;
    display: block;
    padding-right: 5px;
    color: #292F33;
    transition: ease 0.4s;
}
#editor-window-max:hover, #editor-window-min:hover {
    cursor: pointer;
    color: rgba(255, 255, 255, 0.71);
}

.hidden {
    display: none;
}

/* Scroll Bar */

/* width */
::-webkit-scrollbar {
  width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}